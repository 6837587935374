.a1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* or align-items: flex-end; */
  max-height: auto;
  height: 100%;
  filter: none;
}

@media only screen and (max-width:1280px) {
  .a1 {
    justify-content: flex-end;
    max-width: 600px;
    filter: drop-shadow(0px 0px 20px);
    /* or align-items: flex-end; */
  }
}